import React from "react";
import { useTranslation } from 'react-i18next';

const Settings = ({ onClick }) => {
    const { t, i18n } = useTranslation();

    const handleCancel = () => {
        switch (i18n.language) {
            case "ja":
                i18n.changeLanguage("ko");
                break;
            case "ko":
                i18n.changeLanguage("jp");
                break;
            default:
                break;
        }
        onClick();
    };

    return (
        <div className="h-screen w-full fixed left-0 top-0 flex justify-center items-center bg-black bg-opacity-70 text-center">
            <div className="bg-white rounded w-[250px]">
                <div className="border-b px-4 py-2 flex justify-between items-center">
                    <h3 className="font-extrabold text-[#171036]">
                        { t("settingsTitle") }
                    </h3>
                    <span onClick={ handleCancel }>
                        <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path>
                        </svg>
                    </span>
                </div>
                <form className="text-start pt-6 pb-6 pl-14 space-y-2">
                    <div className="space-x-2">
                        <input type="radio" id="japanese" name="options" value="ja" checked={ i18n.language === "ja" } onChange={ () => i18n.changeLanguage('ja') } />
                        <label for="japanese">{ t("settingsJapanese") }</label>
                    </div>
                    <div className="space-x-2">
                        <input type="radio" id="korean" name="options" value="ko" checked={ i18n.language === "ko" } onChange={ () => i18n.changeLanguage('ko') } />
                        <label for="korean">{ t("settingsKorean") }</label>
                    </div>
                </form>
                <div className="flex justify-center items-center w-100 border-t p-3 text-[#171036]">
                    <button className="bg-[#171036] px-3 py-1 rounded text-white" onClick={ onClick }>
                    { t("settingsOk") }
                    </button>
                </div>
            </div>
        </div>
    )
}
export default Settings;