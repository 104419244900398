import React from 'react';
import { useMediaQuery } from "react-responsive";
import { useTranslation } from 'react-i18next';

function Policy() {
    const Desktop = ({ children }) => useMediaQuery({ minWidth: 768 }) ? children : null;
    const Mobile = ({ children }) => useMediaQuery({ maxWidth: 767 }) ? children : null;
    const { t } = useTranslation();
    const policyData = [
        { title: "policyTitle01", contents: "policyContents01"},
        { title: "policyTitle02", contents: "policyContents02"},
        { title: "policyTitle03", contents: "policyContents03"},
        { title: "policyTitle04", contents: "policyContents04"},
        { title: "policyTitle05", contents: "policyContents05"},
        { title: "policyTitle06", contents: "policyContents06"},
        { title: "policyTitle07", contents: "policyContents07"},
        { title: "policyTitle08", contents: "policyContents08"},
        { title: "policyTitle09", contents: "policyContents09"},
        { title: "policyTitle10", contents: "policyContents10"},
        { title: "policyTitle11", contents: "policyContents11"},
        { title: "policyTitle12", contents: "policyContents12"},
        { title: "policyTitle13", contents: "policyContents13"},
    ];
  return (
    <div>
        <Desktop>
            <div id="policy" className="m-auto mt-40 mb-20 w-[1080px] space-y-10 text-[#171036]">
                <div className="text-4xl font-bold text-center">{ t("policySectionTitle") }</div>
                <div>{ t("policySectionContents") }</div>
                {policyData.map((policy, index) => (
                    <React.Fragment key={ index }>
                        <div className="text-2xl font-bold">{ t(policy.title) }</div>
                        <div>
                            {t(policy.contents).split('\n').map((text, index) => (
                                <React.Fragment key={ index }>{ text }<br /><br /></React.Fragment>
                            ))}
                        </div>
                    </React.Fragment>
                ))}
            </div>
        </Desktop>

        <Mobile>
            <div id="policy" className="m-10 mt-20 text-xs space-y-5 text-[#171036]">
                <div className="text-xl font-bold text-center">{ t("policySectionTitle") }</div>
                {policyData.map((policy, index) => (
                    <React.Fragment key={ index }>
                        <div className="text-sm font-bold">{ t(policy.title) }</div>
                        <div>
                            {t(policy.contents).split('\n').map((text, index) => (
                                <React.Fragment key={ index }>{ text }<br /><br /></React.Fragment>
                            ))}
                        </div>
                    </React.Fragment>
                ))}
            </div>
        </Mobile>
    </div>
  );
}

export default Policy;