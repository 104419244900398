import React, { useState } from "react";
import { useMediaQuery } from "react-responsive";
import { useTranslation } from 'react-i18next';
import logo from "../assets/logo.png";
import { HashLink } from 'react-router-hash-link';
import { FaRegPaperPlane } from "react-icons/fa6";
import { AiOutlineGlobal } from "react-icons/ai";
import AppSettings from "./Settings";

const Header = () => {
    const Desktop = ({ children }) => useMediaQuery({ minWidth: 768 }) ? children : null;
    const Mobile = ({ children }) => useMediaQuery({ maxWidth: 767 }) ? children : null;
    const { t } = useTranslation();
    const [showSettings, setShowSettings] = useState(false);
    const handleOnClickSettings = () => {
        setShowSettings(!showSettings);
      }
    return (
        <div>
            <Desktop>
                <nav id="header" className="flex justify-between items-center m-auto pt-3 w-[1080px]">
                    <div className="flex">
                        <img className="h-[70px]" src={ logo } alt="logo" />
                        <div className="flex items-center space-x-1 mr-4 text-[#171036] text-sm font-bold">
                            <div>Japan to Global</div>
                            <FaRegPaperPlane />
                        </div>
                    </div>
                    <div className="flex text-sm">
                        <HashLink 
                            className="rounded-lg px-6 py-2 hover:bg-[#171036] hover:text-white cursor-pointer" 
                            to="/#banner" 
                            scroll={(element) => element.scrollIntoView({ behavior: 'smooth', block: 'start' })}
                        >
                            { t("headerTop") }
                        </HashLink>
                        <HashLink 
                            className="rounded-lg px-6 py-2 hover:bg-[#171036] hover:text-white cursor-pointer" 
                            to="/#service" 
                            scroll={(element) => element.scrollIntoView({ behavior: 'smooth', block: 'start' })}
                        >
                            { t("headerService") }
                        </HashLink>
                        <HashLink 
                            className="rounded-lg px-6 py-2 hover:bg-[#171036] hover:text-white cursor-pointer" 
                            to="/#company" 
                            scroll={(element) => element.scrollIntoView({ behavior: 'smooth', block: 'start' })}
                        >
                            { t("headerCompany") }
                        </HashLink>
                        <HashLink 
                            className="rounded-lg px-6 py-2 hover:bg-[#171036] hover:text-white cursor-pointer" 
                            to="/#inquiry" 
                            scroll={(element) => element.scrollIntoView({ behavior: 'smooth', block: 'start' })}
                        >
                            { t("headerInquiry") }
                        </HashLink>
                        <HashLink 
                            className="text-[#171036] hover:bg-[#171036] hover:text-white font-bold uppercase text-xl px-6 pt-[6px] rounded hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                            onClick={ handleOnClickSettings }
                        >
                            <AiOutlineGlobal className="" />
                        </HashLink>
                        {
                            showSettings && <AppSettings onClick={ handleOnClickSettings }/>
                        }
                    </div>
                </nav>
            </Desktop>
            <Mobile>
                <nav id="header" className="flex items-center justify-between">
                    <div className="w-[160px] mt-1 ml-2">
                        <img src={ logo } alt="logo" />
                    </div>
                    {/* <div className="flex items-center space-x-1 mr-4 text-[#171036] font-bold">
                        <div>Japan to Global</div>
                        <FaRegPaperPlane />
                    </div> */}
                </nav>
            </Mobile>
        </div>
    )
}
export default Header;