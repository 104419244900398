import React, { useState } from 'react';
import { useMediaQuery } from "react-responsive";
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import AppHeader from "./components/Header";
import AppBanner from "./components/Banner";
import AppService from "./components/Service";
import AppCompanyAppeal from './components/CompanyAppeal';
import AppCompany from "./components/Company";
import AppInquiry from "./components/Inquiry";
import AppSettings from "./components/Settings";
import AppTerms from "./components/Terms";
import AppPolicy from "./components/Policy";
import AppFooter from "./components/Footer";
import AppBottomNavigation from "./components/BottomNavigation";
import AppBackNavigation from "./components/BackNavigation";

function App() {
  const Desktop = ({ children }) => useMediaQuery({ minWidth: 768 }) ? children : null;
  const Mobile = ({ children }) => useMediaQuery({ maxWidth: 767 }) ? children : null;
  return (
    <BrowserRouter> 
      <Desktop>
      <Routes>
          <Route path="/" element={ <Home /> } exact />
          <Route path="/terms" element={ <Terms /> } exact />
          <Route path="/policy" element={ <Policy /> } exact />
      </Routes>
      </Desktop>
      <Mobile>
      <Routes>
          <Route path="/" element={ <HomeMobile /> } exact />
          <Route path="/terms" element={ <TermsMobile /> } exact />
          <Route path="/policy" element={ <PolicyMobile /> } exact />
      </Routes>
      </Mobile>
    </BrowserRouter>
  );
}

const Home = () => {
  return (
    <div>
      <header className="fixed top-0 left-0 w-full z-50 bg-gray-50 h-30 shadow-lg">
        <AppHeader />
      </header>
      <body>
        <AppBanner />
        <AppService />
        <AppCompanyAppeal />
        <AppCompany />
        <AppInquiry />
      </body>
      <footer>
        <AppFooter />
      </footer>
    </div>
)}

const HomeMobile = () => {
  const [showSettings, setShowSettings] = useState(false);

  let lastScrollTop = 0

  function handleScroll() {
    let currentScroll = window.pageYOffset || document.documentElement.scrollTop;
    const bottomNav = document.getElementById("bottomNav");

    if (bottomNav) {
      if (currentScroll > lastScrollTop) {
        bottomNav.style.transition = "opacity 0.3s";
        bottomNav.style.opacity = '0';
      } else {
        bottomNav.style.transition = "opacity 0.3s";
        bottomNav.style.opacity = '1';
      }
      lastScrollTop = currentScroll <= 0 ? 0 : currentScroll;
    }
  }

  window.addEventListener("scroll", handleScroll);

  const handleOnClickSettings = () => {
    setShowSettings(!showSettings);
  }

  return (
    <div>
      <header>
        <AppHeader />
      </header>
      <body>
        <AppBanner />
        <AppService />
        <AppCompanyAppeal />
        <AppCompany />
        <AppInquiry />
        {
          showSettings && <AppSettings onClick={ handleOnClickSettings }/>
        }
      </body>
      <footer>
        <AppFooter />
      </footer>
      <div className="fixed bottom-0">
        <AppBottomNavigation onClickSettings={ handleOnClickSettings } />
      </div>
    </div>
)}

const Terms = () => {
  return (
    <div>
      <header className="fixed top-0 left-0 w-full z-50 bg-gray-50 h-30 shadow-lg">
        <AppHeader />
      </header>
      <body>
        <AppTerms />
      </body>
      <footer>
        <AppFooter />
      </footer>
    </div>
)}

const TermsMobile = () => {
  return (
    <div>
      <header>
        <AppBackNavigation />
      </header>
      <body>
        <AppTerms />
      </body>
    </div>
)}

const Policy = () => {
  return (
    <div>
      <header className="fixed top-0 left-0 w-full z-50 bg-gray-50 h-30 shadow-lg">
        <AppHeader />
      </header>
      <body>
        <AppPolicy />
      </body>
      <footer>
        <AppFooter />
      </footer>
    </div>
)}

const PolicyMobile = () => {
return (
  <div>
    <header>
      <AppBackNavigation />
    </header>
    <body>
      <AppPolicy />
    </body>
  </div>
)}

export default App;