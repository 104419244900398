import React, { useState, useRef } from 'react';
import { useMediaQuery } from "react-responsive";
import { useTranslation } from 'react-i18next';
import emailjs from '@emailjs/browser';
import Modal from "./Modal";

const Inquiry = () => {
    const Desktop = ({ children }) => useMediaQuery({ minWidth: 768 }) ? children : null;
    const Mobile = ({ children }) => useMediaQuery({ maxWidth: 767 }) ? children : null;
    const [openTab, setOpenTab] = useState(1);
    const { t } = useTranslation();
    return (
        <div>
            <Desktop>
                <section id="inquiry" className="flex flex-col justify-center items-center mt-[100px] mb-[50px] space-y-2">
                    {/* <div className="w-[70px] border-b border-4 border-[#171036]"></div> */}
                    <div className="text-2xl text-[#171036] font-bold">{ t("inquirySectionTitle") }</div>
                </section>
                <div className="grid grid-cols-1 m-auto w-[800px]">
                    <ul className="flex mb-0 list-none flex-wrap flex-row" role="tablist">
                        <li className="w-1/2 flex-auto text-center">
                            <a 
                                className={ 
                                    `text-lg px-2 py-6 shadow-md rounded block leading-normal cursor-pointer ${
                                        openTab === 1 ? "text-white bg-[#171036]" : "text-[#171036] bg-white"
                                    }`
                                }
                                onClick={ (e) => {
                                    e.preventDefault();
                                    setOpenTab(1);   
                                }}
                                data-toggle="tab"
                                href="#link1"
                                role="tablist"
                            >                                
                                { t("inquiryPersonalTabTitle") }
                            </a>
                        </li>
                        <li className="w-1/2 flex-auto text-center">
                            <a 
                                className={ 
                                    `text-lg px-2 py-6 shadow-md rounded block leading-normal cursor-pointer ${
                                        openTab === 2 ? "text-white bg-[#171036]" : "text-[#171036] bg-white"
                                    }`
                                }
                                onClick={ (e) => {
                                    e.preventDefault();
                                    setOpenTab(2);   
                                }}
                                data-toggle="tab"
                                href="#link1"
                                role="tablist"
                            >                                
                                { t("inquiryCorporateTabTitle") }
                            </a>
                        </li>
                    </ul>
                    <div className="relative flex flex-col min-w-0 break-words text-center">
                        <div className="flex-auto">
                            <div className="tab-content tab-space">
                                <div id="link1" className={ openTab === 1 ? "block" : "hidden"}>
                                    <PersonalForm />
                                </div>
                                <div id="link2" className={ openTab === 2 ? "block" : "hidden"}>
                                    <CorporateForm />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Desktop>

            <Mobile>
                <section id="inquiry" className="flex flex-col justify-center items-center pt-20 pb-5">
                    {/* <div className="w-[40px] border-b border-2 border-[#171036]"></div> */}
                    <div className="text-lg text-[#171036] font-bold">{ t("inquirySectionTitle") }</div>
                </section>
                <div className="grid grid-cols-1 p-4 mt-2">
                    <ul className="flex mb-0 list-none flex-wrap flex-row" role="tablist">
                        <li className="w-1/4 flex-auto text-center">
                            <a 
                                className={ 
                                    `text-xs px-2 py-2 shadow-md rounded block leading-normal cursor-pointer ${
                                        openTab === 1 ? "text-white bg-[#171036]" : "text-[#171036] bg-white"
                                    }`
                                }
                                onClick={ (e) => {
                                    e.preventDefault();
                                    setOpenTab(1);   
                                }}
                                data-toggle="tab"
                                href="#link1"
                                role="tablist"
                            >                                
                                { t("inquiryPersonalTabTitle") }
                            </a>
                        </li>
                        <li className="w-1/4 flex-auto text-center">
                            <a 
                                className={ 
                                    `text-xs px-2 py-2 shadow-md rounded block leading-normal cursor-pointer ${
                                        openTab === 2 ? "text-white bg-[#171036]" : "text-[#171036] bg-white"
                                    }`
                                }
                                onClick={ (e) => {
                                    e.preventDefault();
                                    setOpenTab(2);   
                                }}
                                data-toggle="tab"
                                href="#link1"
                                role="tablist"
                            >                                
                                { t("inquiryCorporateTabTitle") }
                            </a>
                        </li>
                    </ul>
                    <div className="relative flex flex-col min-w-0 break-words text-center">
                        <div className="flex-auto">
                            <div className="tab-content tab-space">
                                <div id="link1" className={ openTab === 1 ? "block" : "hidden"}>
                                    <PersonalMobileForm />
                                </div>
                                <div id="link2" className={ openTab === 2 ? "block" : "hidden"}>
                                    <CorporateMobileForm />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Mobile>
        </div>
    )
}

// Personal Desktop Form
const PersonalForm = () => {
    const { t } = useTranslation();
    const form = useRef();
    const [showForm, setShowForm] = useState(false);
    const [formData, setFormData] = useState({
        userName: '',
        userEmail: '',
        userPhoneNumber: '',
        isFromBlog: false,
        isFromSite: false,
        isFromInvite: false,
        othersText: ''
    });
    const [openModal, setOpenModal] = useState(false);
    const handleModal = () => {
        setOpenModal(false);
        window.location.reload();
    };

    const handleInputChange = (event) => {
        const { name, value, type, checked } = event.target;
        const inputValue = type === 'checkbox' ? checked : value;

        setFormData((prevData) => ({
            ...prevData,
            [name]: inputValue,
        }));
    };

    const sendEmail = (e) => {
        e.preventDefault();
        emailjs.sendForm("service_v9ozdfi", "template_o18vfmt", form.current, "uu8_BX62s5EixbRZJ")
        .then((result) => {
            console.log(result.text);
            setOpenModal(true);
        }, (error) => {
            console.log(error.text);
        });
    };

    return (
        <div>
            <form className="flex flex-col text-[#171036] ml-10 mr-10 mt-10" ref={ form } onSubmit={ sendEmail }>
                <ul className="text-lg text-left space-y-10">
                    <li className="flex flex-col border-b border-t-0 border-l-0 border-r-0 border-[#171036]">
                        <label className="font-bold">{ t("inquiryPersonalName") }<span className="text-red-500">*</span></label>
                        <input 
                            className="h-16 focus:outline-none" 
                            type="text" 
                            placeholder={ t("inquiryPersonalNamePlaceholder") } 
                            name="userName"
                            onChange={ handleInputChange }
                            value={ formData.userName }
                            required
                        />
                    </li> 
                    <li className="flex flex-col border-b border-t-0 border-l-0 border-r-0 border-[#171036]">
                        <label className="font-bold">{ t("inquiryPersonalEmail") }<span className="text-red-500">*</span></label>
                        <input 
                            className="h-16 focus:outline-none" 
                            type="email" 
                            placeholder={ t("inquiryPersonalEmailPlaceholder") } 
                            name="userEmail"
                            onChange={ handleInputChange }
                            value={ formData.userEmail }
                            required
                        />
                    </li> 
                    <li className="flex flex-col border-b border-t-0 border-l-0 border-r-0 border-[#171036]">
                        <label className="font-bold">{ t("inquiryPersonalPhoneNumber") }<span className="text-red-500">*</span></label>
                        <input 
                            className="h-16 focus:outline-none" 
                            type="tel" 
                            placeholder={ t("inquiryPersonalPhoneNumberPlaceholder") }
                            name="userPhoneNumber"
                            onChange={ handleInputChange }
                            value={ formData.userPhoneNumber }
                            required
                        />
                    </li>
                    <li className="flex flex-col space-y-5">
                        <label className="font-bold">{ t("inquiryPersonalRoute") }<span className="text-red-500">*</span></label>
                        <ul className="flex justify-between">
                        <li className="flex items-center space-x-3">
                                <input 
                                    id="checkbox-blog" 
                                    type="checkbox" 
                                    className="w-6 h-6"
                                    name="isFromBlog"
                                    onChange={ handleInputChange }
                                    value={ formData.isFromBlog }
                                />
                                <label for="checkbox-blog">{ t("inquiryPersonalBlog") }</label>
                            </li> 
                            <li className="flex items-center space-x-3">
                                <input 
                                    id="checkbox-search" 
                                    type="checkbox" 
                                    className="w-6 h-6"
                                    name="isFromSite"
                                    onChange={ handleInputChange }
                                    value={ formData.isFromSite }
                                />
                                <label for="checkbox-search">{ t("inquiryPersonalSearch") }</label>
                            </li> 
                            <li className="flex items-center space-x-3">
                                <input 
                                    id="checkbox-invite" 
                                    type="checkbox" 
                                    className="w-6 h-6" 
                                    name="isFromInvite"
                                    onChange={ handleInputChange }
                                    value={ formData.isFromInvite }
                                />
                                <label for="checkbox-invite">{ t("inquiryPersonalInvite") }</label>
                            </li> 
                            <li className="flex items-center space-x-3">
                                <input id="checkbox-others" type="checkbox" className="w-6 h-6" onChange={ () => setShowForm(!showForm) } />
                                <label for="checkbox-others">{ t("inquiryPersonalOthers") }</label>
                            </li>
                        </ul>
                        {
                            showForm && (
                                <li className="flex flex-col border-b border-t-0 border-l-0 border-r-0 border-[#171036]">
                                    <input 
                                        className="h-16 focus:outline-none" 
                                        type="text" 
                                        placeholder={ t("inquiryPersonalOthersPlaceholder") } 
                                        name="othersText"
                                        onChange={ handleInputChange }
                                        value={ formData.othersText }
                                    />
                                </li> 
                            )
                        }
                    </li>
                    <li className="flex items-center justify-between">
                        <label className="font-bold">{ t("inquiryPersonalPermissionTitle") }<span className="text-red-500">*</span></label>
                        <div className="flex items-center justify-center space-x-3 text-xl">
                            <input id="checkbox-permission" type="checkbox" className="w-6 h-6" required />
                            <label for="checkbox-permission">{ t("inquiryPersonalPermission") }</label>
                        </div>
                    </li>
                </ul>
                <div className="flex flex-col items-center justify-center m-20 space-y-10">
                    <button className="bg-[#F9E089] rounded-lg w-[300px] h-[100px] text-xl text-[#171036] font-bold" type="submit">  
                        { t("inquiryPersonalSubmitTitle") }
                    </button>
                    <p className="text-gray-500">{ t("inquiryPersonalSubmitSubTitle") }</p>
                </div>
            </form>
            {openModal && (
                <Modal onClick={ handleModal } />
            )}
        </div>
    )}

// Corporate Desktop Form
const CorporateForm = () => {
    const { t } = useTranslation();
    const form = useRef();
    const [formData, setFormData] = useState({
        userName: '',
        companyEmail: '',
        companyName: '',
        companyAddress: '',
        grade: '',
    });
    const [openModal, setOpenModal] = useState(false);

    const handleModal = () => {
        setOpenModal(false);
        window.location.reload();
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;

        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const sendEmail = (e) => {
        e.preventDefault();
        emailjs.sendForm("service_v9ozdfi", "template_buewqae", form.current, "uu8_BX62s5EixbRZJ")
        .then((result) => {
            console.log(result.text);
            setOpenModal(true);
        }, (error) => {
            console.log(error.text);
        });
    };

    return (
        <div>
            <form className="flex flex-col text-[#171036] ml-10 mr-10 mt-10" ref={ form } onSubmit={ sendEmail }>
                <ul className="text-lg text-left space-y-10">
                    <li className="flex flex-col border-b border-t-0 border-l-0 border-r-0 border-[#171036]">
                        <label className="font-bold">{ t("inquiryCorperateCompanyName") }<span className="text-red-500">*</span></label>
                        <input 
                            className="h-16 focus:outline-none" 
                            type="text" 
                            placeholder={ t("inquiryCorperateCompanyNamePlaceholder") } 
                            name="companyName"
                            onChange={ handleInputChange }
                            value={ formData.companyName }
                            required
                        />
                    </li> 
                    <li className="flex flex-col border-b border-t-0 border-l-0 border-r-0 border-[#171036]">
                        <label className="font-bold">{ t("inquiryCorperatePlace") }<span className="text-red-500">*</span></label>
                        <input 
                            className="h-16 focus:outline-none" 
                            type="text" 
                            placeholder={ t("inquiryCorperatePlacePlaceholder") } 
                            name="companyAddress"
                            onChange={ handleInputChange }
                            value={ formData.companyAddress }
                            required
                        />
                    </li>
                    <li className="flex flex-col border-b border-t-0 border-l-0 border-r-0 border-[#171036]">
                        <label className="font-bold">{ t("inquiryCorperateName") }<span className="text-red-500">*</span></label>
                        <input 
                            className="h-16 focus:outline-none" 
                            type="text" 
                            placeholder={ t("inquiryCorperateNamePlaceholder") } 
                            name="userName"
                            onChange={ handleInputChange }
                            value={ formData.userName }
                            required
                        />
                    </li> 
                    <li className="flex flex-col border-b border-t-0 border-l-0 border-r-0 border-[#171036]">
                        <label className="font-bold">{ t("inquiryCorperateGrade") }<span className="text-red-500">*</span></label>
                        <input 
                            className="h-16 focus:outline-none" 
                            type="text" 
                            placeholder={ t("inquiryCorperateGradePlaceholder") } 
                            name="grade"
                            onChange={ handleInputChange }
                            value={ formData.grade }
                            required
                        />
                    </li>
                    <li className="flex flex-col border-b border-t-0 border-l-0 border-r-0 border-[#171036]">
                        <label className="font-bold">{ t("inquiryCorperateEmail") }<span className="text-red-500">*</span></label>
                        <input 
                            className="h-16 focus:outline-none" 
                            type="email" 
                            placeholder={ t("inquiryCorperateEmailPlaceholder") } 
                            name="companyEmail"
                            onChange={ handleInputChange }
                            value={ formData.companyEmail }
                            required
                        />
                    </li> 
                    <li className="flex items-center justify-between">
                        <label className="font-bold">{ t("inquiryPersonalPermissionTitle") }<span className="text-red-500">*</span></label>
                        <div className="flex items-center justify-center space-x-3 text-xl">
                            <input id="checkbox-permission" type="checkbox" className="w-6 h-6" required />
                            <label for="checkbox-permission">{ t("inquiryPersonalPermission") }</label>
                        </div>
                    </li>
                </ul>
                <div className="flex flex-col items-center justify-center m-20 space-y-10">
                    <button className="bg-[#F9E089] rounded-lg w-[300px] h-[100px] text-xl text-[#171036] font-bold" type="submit">{ t("inquiryCorperateSubmitTitle") }</button>
                    <p className="text-gray-500">{ t("inquiryCorperateSubmitSubTitle") }</p>
                </div>
            </form>
            {openModal && (
                <Modal onClick={ handleModal } />
            )}
        </div>
    )}

// Personal Mobile Form
const PersonalMobileForm = () => {
    const { t } = useTranslation();
    const form = useRef();
    const [showForm, setShowForm] = useState(false);
    const [formData, setFormData] = useState({
        userName: '',
        userEmail: '',
        userPhoneNumber: '',
        isFromBlog: false,
        isFromSite: false,
        isFromInvite: false,
        othersText: ''
    });
    const [openModal, setOpenModal] = useState(false);
    const handleModal = () => {
        setOpenModal(false);
        window.location.reload();
    };

    const handleInputChange = (event) => {
        const { name, value, type, checked } = event.target;
        const inputValue = type === 'checkbox' ? checked : value;

        setFormData((prevData) => ({
            ...prevData,
            [name]: inputValue,
        }));
    };

    const sendEmail = (e) => {
        e.preventDefault();
        emailjs.sendForm("service_v9ozdfi", "template_o18vfmt", form.current, "uu8_BX62s5EixbRZJ")
        .then((result) => {
            console.log(result.text);
            setOpenModal(true);
        }, (error) => {
            console.log(error.text);
        });
    };

    return (
        <div>
            <form className="flex flex-col text-[#171036] mt-5" ref={ form } onSubmit={ sendEmail }>
                <ul className="text-sm text-left space-y-4">
                    <li className="flex flex-col border-b border-t-0 border-l-0 border-r-0 border-[#171036]">
                        <label className="font-bold">{ t("inquiryPersonalName") }<span className="text-red-500">*</span></label>
                        <input 
                            className="h-8 focus:outline-none" 
                            type="text" 
                            placeholder={ t("inquiryPersonalNamePlaceholder") } 
                            name="userName"
                            onChange={ handleInputChange }
                            value={ formData.userName }
                            required
                        />
                    </li> 
                    <li className="flex flex-col border-b border-t-0 border-l-0 border-r-0 border-[#171036]">
                        <label className="font-bold">{ t("inquiryPersonalEmail") }<span className="text-red-500">*</span></label>
                        <input 
                            className="h-8 focus:outline-none" 
                            type="email" 
                            placeholder={ t("inquiryPersonalEmailPlaceholder") } 
                            name="userEmail"
                            onChange={ handleInputChange }
                            value={ formData.userEmail }
                            required
                        />
                    </li> 
                    <li className="flex flex-col border-b border-t-0 border-l-0 border-r-0 border-[#171036]">
                        <label className="font-bold">{ t("inquiryPersonalPhoneNumber") }<span className="text-red-500">*</span></label>
                        <input 
                            className="h-8 focus:outline-none" 
                            type="tel" 
                            placeholder={ t("inquiryPersonalPhoneNumberPlaceholder") } 
                            name="userPhoneNumber"
                            onChange={ handleInputChange }
                            value={ formData.userPhoneNumber }
                            required
                        />
                    </li>
                    <li className="flex flex-col space-y-2">
                        <label className="font-bold">{ t("inquiryPersonalRoute") }<span className="text-red-500">*</span></label>
                        <ul className="flex justify-between text-[10px]">
                            <li className="flex items-center space-x-1">
                                <input 
                                    id="checkbox-blog" 
                                    type="checkbox" 
                                    name="isFromBlog"
                                    onChange={ handleInputChange }
                                    value={ formData.isFromBlog }
                                />
                                <label for="checkbox-blog">{ t("inquiryPersonalBlog") }</label>
                            </li> 
                            <li className="flex items-center space-x-1">
                                <input 
                                    id="checkbox-search" 
                                    type="checkbox" 
                                    name="isFromSite"
                                    onChange={ handleInputChange }
                                    value={ formData.isFromSite }
                                />
                                <label for="checkbox-search">{ t("inquiryPersonalSearch") }</label>
                            </li> 
                            <li className="flex items-center space-x-1">
                                <input 
                                    id="checkbox-invite" 
                                    type="checkbox" 
                                    name="isFromInvite"
                                    onChange={ handleInputChange }
                                    value={ formData.isFromInvite }
                                />
                                <label for="checkbox-invite">{ t("inquiryPersonalInvite") }</label>
                            </li> 
                            <li className="flex items-center space-x-1">
                                <input id="checkbox-others" type="checkbox" onChange={ () => setShowForm(!showForm) } />
                                <label for="checkbox-others">{ t("inquiryPersonalOthers") }</label>
                            </li>
                        </ul>
                        {
                            showForm && (
                                <li className="border-b border-t-0 border-l-0 border-r-0 border-[#171036]">
                                    <input 
                                        className="h-8 focus:outline-none" 
                                        type="text" 
                                        placeholder={ t("inquiryPersonalOthersPlaceholder") }
                                        name="othersText"
                                        onChange={ handleInputChange }
                                        value={ formData.othersText } 
                                    />
                                </li> 
                            )
                        }
                    </li>
                    <li className="flex items-center justify-between">
                        <label className="font-bold">{ t("inquiryPersonalPermissionTitle") }<span className="text-red-500">*</span></label>
                        <div className="flex items-center justify-center space-x-1 text-xs">
                            <input id="checkbox-permission" type="checkbox" required />
                            <label for="checkbox-permission">{ t("inquiryPersonalPermission") }</label>
                        </div>
                    </li>
                </ul>
                <div className="flex flex-col items-center justify-center m-4 space-y-2">
                    <button className="bg-[#F9E089] rounded-lg w-[150px] h-[40px] text-sm text-[#171036] font-bold" type="submit">{ t("inquiryPersonalSubmitTitle") }</button>
                    <p className="text-gray-500 text-[9px]">{ t("inquiryPersonalSubmitSubTitle") }</p>
                </div>
            </form>
            {openModal && (
                <Modal onClick={ handleModal } />
            )}
        </div>
    )}

// Corporate Mobile Form
const CorporateMobileForm = () => {
    const { t } = useTranslation();
    const form = useRef();
    const [formData, setFormData] = useState({
        userName: '',
        companyEmail: '',
        companyName: '',
        companyAddress: '',
        grade: '',
    });
    const [openModal, setOpenModal] = useState(false);

    const handleModal = () => {
        setOpenModal(false);
        window.location.reload();
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;

        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const sendEmail = (e) => {
        e.preventDefault();
        emailjs.sendForm("service_v9ozdfi", "template_buewqae", form.current, "uu8_BX62s5EixbRZJ")
        .then((result) => {
            console.log(result.text);
            setOpenModal(true);
        }, (error) => {
            console.log(error.text);
        });
    };
    return (
        <div>
            <form className="flex flex-col text-[#171036] mt-5" ref={ form } onSubmit={ sendEmail }>
                <ul className="text-sm text-left space-y-4">
                    <li className="flex flex-col border-b border-t-0 border-l-0 border-r-0 border-[#171036]">
                        <label className="font-bold">{ t("inquiryCorperateCompanyName") }<span className="text-red-500">*</span></label>
                        <input 
                            className="h-8 focus:outline-none" 
                            type="text" 
                            placeholder={ t("inquiryCorperateCompanyNamePlaceholder") } 
                            name="companyName"
                            onChange={ handleInputChange }
                            value={ formData.companyName }
                            required
                        />
                    </li> 
                    <li className="flex flex-col border-b border-t-0 border-l-0 border-r-0 border-[#171036]">
                        <label className="font-bold">{ t("inquiryCorperatePlace") }<span className="text-red-500">*</span></label>
                        <input 
                            className="h-8 focus:outline-none" 
                            type="text" 
                            placeholder={ t("inquiryCorperatePlacePlaceholder") } 
                            name="companyAddress"
                            onChange={ handleInputChange }
                            value={ formData.companyAddress }
                            required
                        />
                    </li>
                    <li className="flex flex-col border-b border-t-0 border-l-0 border-r-0 border-[#171036]">
                        <label className="font-bold">{ t("inquiryCorperateName") }<span className="text-red-500">*</span></label>
                        <input 
                            className="h-8 focus:outline-none" 
                            type="text" 
                            placeholder={ t("inquiryCorperateNamePlaceholder") } 
                            name="userName"
                            onChange={ handleInputChange }
                            value={ formData.userName }
                            required
                        />
                    </li> 
                    <li className="flex flex-col border-b border-t-0 border-l-0 border-r-0 border-[#171036]">
                        <label className="font-bold">{ t("inquiryCorperateGrade") }<span className="text-red-500">*</span></label>
                        <input 
                            className="h-8 focus:outline-none" 
                            type="text" 
                            placeholder={ t("inquiryCorperateGradePlaceholder") } 
                            name="grade"
                            onChange={ handleInputChange }
                            value={ formData.grade }
                            required
                        />
                    </li>
                    <li className="flex flex-col border-b border-t-0 border-l-0 border-r-0 border-[#171036]">
                        <label className="font-bold">{ t("inquiryCorperateEmail") }<span className="text-red-500">*</span></label>
                        <input 
                            className="h-8 focus:outline-none" 
                            type="email" 
                            placeholder={ t("inquiryCorperateEmailPlaceholder") } 
                            name="companyEmail"
                            onChange={ handleInputChange }
                            value={ formData.companyEmail }
                            required
                        />
                    </li>
                    <li className="flex items-center justify-between">
                        <label className="font-bold">{ t("inquiryPersonalPermissionTitle") }<span className="text-red-500">*</span></label>
                        <div className="flex items-center justify-center space-x-1 text-xs">
                            <input id="checkbox-permission" type="checkbox" required />
                            <label for="checkbox-permission">{ t("inquiryPersonalPermission") }</label>
                        </div>
                    </li>
                </ul>
                <div className="flex flex-col items-center justify-center m-4 space-y-2">
                    <button className="bg-[#F9E089] rounded-lg w-[150px] h-[40px] text-sm text-[#171036] font-bold" type="submit">{ t("inquiryCorperateSubmitTitle") }</button>
                    <p className="text-gray-500 text-[9px]">{ t("inquiryCorperateSubmitSubTitle") }</p>
                </div>
            </form>
            {openModal && (
                <Modal onClick={ handleModal } />
            )}
        </div>
    )}
// function validatePhoneNumber(phoneNumber) {
//     const pattern = /^\d{3}-\d{4}-\d{4}$/;
//     return pattern.test(phoneNumber);
// }

export default Inquiry;