import React from "react";
import { useMediaQuery } from "react-responsive";
import { useTranslation } from 'react-i18next';
import { FaRegBuilding } from "react-icons/fa";
import { PiMoneyFill } from "react-icons/pi";
import { PiCertificateFill } from "react-icons/pi";
import { FaUserTie } from "react-icons/fa";

const Company = () => {
    const Desktop = ({ children }) => useMediaQuery({ minWidth: 768 }) ? children : null;
    const Mobile = ({ children }) => useMediaQuery({ maxWidth: 767 }) ? children : null;
    const { t } = useTranslation();
    return (
        <div>
            <Desktop>
                <section id="company" className="flex flex-col justify-center items-center mt-[100px] mb-[50px] space-y-2">
                    {/* <div className="w-[70px] border-b border-4 border-[#171036]"></div> */}
                    <div className="text-2xl text-[#171036] font-bold">{ t("companySectionTitle") }</div>
                </section>
                <div className="flex w-[1080px] m-auto text-[#171036] pb-20">
                    <div className="flex flex-col items-center w-1/2 space-y-3">
                        <p className="text-xs text-gray-600">{ t("companyNameKey") }</p>
                        <FaRegBuilding className="text-6xl" />
                        <p className="text-sm font-bold">{ t("companyNameValue") }</p>
                    </div>
                    <div className="flex flex-col items-center w-1/2 space-y-3">
                        <p className="text-xs text-gray-600">{ t("companyCeoKey") }</p>
                        <FaUserTie className="text-6xl" />
                        <p className="text-sm font-bold">{ t("companyCeoValue") }</p>
                    </div>
                    <div className="flex flex-col items-center w-1/2 space-y-3">
                        <p className="text-xs text-gray-600">{ t("companyAssetKey") }</p>
                        <PiMoneyFill className="text-6xl" />
                        <p className="text-sm font-bold">{ t("companyAssetValue") }</p>
                    </div>
                    <div className="flex flex-col items-center w-1/2 space-y-3">
                        <p className="text-xs text-gray-600">{ t("companyCertificateKey") }</p>
                        <PiCertificateFill className="text-6xl" />
                        <p className="text-sm font-bold">{ t("companyCertificateValue") }</p>
                    </div>
                </div>
                <img className="w-[1080px] m-auto" src={ t("companyBusiness") } alt="business" />
            </Desktop>

            <Mobile>
                <section id="company" className="flex flex-col justify-center items-center pt-10 pb-5">
                    {/* <div className="w-[40px] border-b border-2 border-[#171036]"></div> */}
                    <div className="text-lg text-[#171036] font-bold">{ t("companySectionTitle") }</div>
                </section>
                <div className="flex flex-col space-y-10 text-[#171036] m-5 pb-5">
                    <div className="flex">
                        <div className="flex flex-col items-center w-1/2 space-y-3">
                            <p className="text-xs text-gray-600">{ t("companyNameKey") }</p>
                            <FaRegBuilding className="text-6xl" />
                            <p className="text-sm font-bold">{ t("companyNameValue") }</p>
                        </div>
                        <div className="flex flex-col items-center w-1/2 space-y-3">
                            <p className="text-xs text-gray-600">{ t("companyCeoKey") }</p>
                            <FaUserTie className="text-6xl" />
                            <p className="text-sm font-bold">{ t("companyCeoValue") }</p>
                        </div>
                    </div>
                    <div className="flex">
                        <div className="flex flex-col items-center w-1/2 space-y-3">
                            <p className="text-xs text-gray-600">{ t("companyAssetKey") }</p>
                            <PiMoneyFill className="text-6xl" />
                            <p className="text-sm font-bold">{ t("companyAssetValue") }</p>
                        </div>
                        <div className="flex flex-col items-center w-1/2 space-y-3">
                            <p className="text-xs text-gray-600">{ t("companyCertificateKey") }</p>
                            <PiCertificateFill className="text-6xl" />
                            <p className="text-sm font-bold">{ t("companyCertificateValue") }</p>
                        </div>
                    </div>
                </div>
                <img src={ t("companyBusiness") } alt="business" />
            </Mobile>
        </div>
    )
}
export default Company;