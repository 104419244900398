import React from "react";
import { useMediaQuery } from "react-responsive";
import { useTranslation } from 'react-i18next';
import { HashLink } from 'react-router-hash-link';

const Footer = () => {
    const Desktop = ({ children }) => useMediaQuery({ minWidth: 768 }) ? children : null;
    const Mobile = ({ children }) => useMediaQuery({ maxWidth: 767 }) ? children : null;
    const { t } = useTranslation();
    return (
        <div>
            <Desktop>
                <div className="h-[250px] bg-[#171036] text-white">
                    <div className="m-auto w-[1080px] h-[250px] p-5">
                        <div className="flex items-center justify-between">
                            <div className="flex flex-col space-y-6">
                                <HashLink 
                                    to="/#header" 
                                    scroll={(element) => element.scrollIntoView({ behavior: 'smooth', block: 'start' })}
                                    className="font-bold text-4xl"
                                >
                                    CLP Career
                                </HashLink>
                                <div className="text-sm space-y-1">
                                    <div>info@clppartners.co.jp</div>
                                    <div>〒160-0023</div>
                                    <div>{ t("footerAddress") }</div>
                                    <div>{ t("footerCertificate") }</div>
                                    <div>&copy;2023 CLP Partners Inc. All Right Reserved.</div>
                                </div>
                            </div>
                            <div className="flex flex-col items-center justify-center space-y-5 underline">
                                <HashLink to="/terms/#terms" scroll={(element) => element.scrollIntoView({ behavior: 'smooth', block: 'start' })}>{ t("footerTerms") }</HashLink>
                                <HashLink to="/policy/#policy" scroll={(element) => element.scrollIntoView({ behavior: 'smooth', block: 'start' })}>{ t("footerPrivacyPolicy") }</HashLink>
                            </div>
                        </div>
                    </div>
                </div>
            </Desktop>
            <Mobile>
                <div className="flex flex-col items-center h-[250px] bg-[#171036] text-[10px] text-white space-y-2">
                    <HashLink 
                        to="/#header" 
                        scroll={(element) => element.scrollIntoView({ behavior: 'smooth', block: 'start' })}
                        className="font-bold text-lg m-3"
                    >
                        CLP Career
                    </HashLink>
                    <HashLink className="underline" to="/terms/#terms" scroll={(element) => element.scrollIntoView({ behavior: 'smooth', block: 'start' })}>{ t("footerTerms") }</HashLink>
                    <HashLink className="underline" to="/policy/#policy" scroll={(element) => element.scrollIntoView({ behavior: 'smooth', block: 'start' })}>{ t("footerPrivacyPolicy") }</HashLink>
                    <div className="flex flex-col items-center">
                        <div>{ t("footerCertificate") }</div>
                        <div>&copy;2023 CLP Partners Inc. All Right Reserved.</div>
                    </div>
                </div>
            </Mobile>
        </div>
    )
}
export default Footer;