import React from "react";
import { useMediaQuery } from "react-responsive";
import { useTranslation } from 'react-i18next';
import step01 from "../assets/service_step_01.png";
import step02 from "../assets/service_step_02.png";
import step03 from "../assets/service_step_03.png";
import step04 from "../assets/service_step_04.png";
import step05 from "../assets/service_step_05.png";
import step06 from "../assets/service_step_06.png";
import arrow from "../assets/service_arrow.png";

const Service = () => {
    const Desktop = ({ children }) => useMediaQuery({ minWidth: 768 }) ? children : null;
    const Mobile = ({ children }) => useMediaQuery({ maxWidth: 767 }) ? children : null;
    const { t } = useTranslation();
    const Contents = [
        {icon: step01, title: t("serviceStepTitle01"), message: t("serviceStepMessage01"), backgroundColor: "bg-ransparent"},
        {icon: step02, title: t("serviceStepTitle02"), message: t("serviceStepMessage02"), backgroundColor: "bg-[#F4F3F5]"},
        {icon: step03, title: t("serviceStepTitle03"), message: t("serviceStepMessage03"), backgroundColor: "bg-transparent"},
        {icon: step04, title: t("serviceStepTitle04"), message: t("serviceStepMessage04"), backgroundColor: "bg-[#F4F3F5]"},
        {icon: step05, title: t("serviceStepTitle05"), message: t("serviceStepMessage05"), backgroundColor: "bg-transparent"},
        {icon: step06, title: t("serviceStepTitle06"), message: t("serviceStepMessage06"), backgroundColor: "bg-[#F4F3F5]"},
    ];
    return (
        <div>
            <Desktop>
                <section id="service" className="flex flex-col justify-center items-center mt-[100px] mb-[50px] space-y-2">
                    {/* <div className="w-[70px] border-b border-4 border-[#171036]"></div> */}
                    <div className="text-2xl text-[#171036] font-bold">{ t("serviceSectionTitle") }</div>
                    <div className="text-[#171036]">{ t("serviceSubTitle") }</div>
                </section>
                <div className="relative m-auto w-[1080px]">
                    <div className="absolute indent-0 w-[370px] mt-[200px] ml-[200px]">
                        <img src={ arrow } alt="arrow" />
                    </div>
                    {
                        Contents.map((content, i) => (
                            <ul className={`flex items-center justify-around h-[200px] ${ content.backgroundColor }`}>
                                <li className="flex flex-col items-center w-20 text-[#171036]">
                                    <div className="font-bold text-lg">Step</div>
                                    <div className="font-bold text-4xl">{ i + 1 }</div>
                                </li>
                                <li>
                                    <img className="w-[170px]" src={ content.icon } alt="step" />
                                </li>
                                <li className="w-[40%] text-[#171036] space-y-7">
                                    <div className="font-bold text-xl">{ content.title }</div>
                                    <div className="text-lg">{ content.message }</div>
                                </li>
                            </ul>
                        ))
                    }
                </div>
                <div className="m-auto mt-20 w-[1080px]">
                    <img src={ t("serviceAppealImageUrl") } alt="appeal" />
                </div>
            </Desktop>

            <Mobile>
                <section id="service" className="flex flex-col justify-center items-center pt-10">
                    {/* <div className="w-[40px] border-b border-2 border-[#171036]"></div> */}
                    <div className="text-lg text-[#171036] font-bold">{ t("serviceSectionTitle") }</div>
                    <div className="text-xs text-[#171036]">{ t("serviceSubTitle") }</div>
                </section>
                <div>
                    {
                        Contents.map((content, i) => (
                            <div className={`flex flex-row items-center h-[160px] space-x-5 px-4 ${ content.backgroundColor }`}>
                                <div className="flex flex-col items-center text-[#171036]">
                                    <div className="font-bold text-sm">Step</div>
                                    <div className="font-bold text-3xl">{ i + 1 }</div>
                                </div>
                                <div className="w-[30%]">
                                    <img src={ content.icon } alt="step" />
                                </div>
                                <div className="w-[65%] text-[#171036] space-y-2">
                                    <div className="font-bold text-md">{ content.title }</div>
                                    <div className="text-xs">{ content.message }</div>
                                </div>
                            </div>
                        ))
                    }
                </div>
                <div className="flex justify-center items-center pt-5">
                    <img className="w-[90%]" src={ t("serviceAppealMobileImageUrl") } alt="appeal" />
                </div>
            </Mobile>
        </div>
    )
}
export default Service;