import React from "react";
import { useMediaQuery } from 'react-responsive';
import { useTranslation } from 'react-i18next';
import { AiFillHome, AiFillGift, AiOutlineGlobal } from "react-icons/ai";
import { HiOutlineOfficeBuilding } from "react-icons/hi";
import { LuMailPlus } from "react-icons/lu";
import { HashLink } from 'react-router-hash-link';

const Navigation = ({ onClickSettings }) => {
    const Desktop = ({ children }) => useMediaQuery({ minWidth: 768 }) ? children : null;
    const Mobile = ({ children }) => useMediaQuery({ maxWidth: 767 }) ? children : null;
    const { t } = useTranslation();
    return (
        <div>
            <Desktop>
                <div id="bottomNav"></div>
            </Desktop>
            <Mobile>
                <div id="bottomNav" className="grid place-items-center h-[45px]">
                    <div className="relative w-screen h-[45px] grid grid-cols-2 gap-[4.5rem] text-gray-600">
                        <div className="relative z-10 bg-[#171036] grid grid-cols-2 place-items-center rounded-r-[2rem] rounded-l-lg">
                            <HashLink to="/#header" scroll={(element) => element.scrollIntoView({ behavior: 'smooth', block: 'start' })}>
                                <button className="btn flex flex-col items-center justify-center" title="home">
                                    <AiFillHome className="text-white w-[20px] h-[20px]" />
                                    <p className="text-[6px] text-white font-bold pointer-events-none">{ t("bottomNavigationHome") }</p>
                                </button>
                            </HashLink>
                            <HashLink to="/#service" scroll={(element) => element.scrollIntoView({ behavior: 'smooth', block: 'start' })}>
                                <button className="btn flex flex-col items-center justify-center" title="service">
                                    <AiFillGift className="text-white w-[20px] h-[20px]" />
                                    <p className="text-[6px] text-white font-bold">{ t("bottomNavigationService") }</p>
                                </button>
                            </HashLink>
                        </div>
                        <div className="relative z-10 bg-[#171036] grid grid-cols-2 place-items-center rounded-l-[2rem] rounded-r-lg">
                            <HashLink to="/#company" scroll={(element) => element.scrollIntoView({ behavior: 'smooth', block: 'start' })}>
                                <button className="btn flex flex-col items-center justify-center" title="company">
                                    <HiOutlineOfficeBuilding className="text-white w-[20px] h-[20px]" />
                                    <p className="text-[6px] text-white font-bold">{ t("bottomNavigationCompany") }</p>
                                </button>
                            </HashLink>
                            <button data-modal-target="popup-modal" data-modal-toggle="popup-modal" className="btn flex flex-col items-center justify-center" title="setting" onClick={ onClickSettings }>
                                <AiOutlineGlobal className="text-white w-[20px] h-[20px]" />
                                <p className="text-[6px] text-white font-bold">{ t("bottomNavigationSettings") }</p>
                            </button>
                        </div>

                        <div className="absolute -top-10 left-1/2 -translate-x-1/2 h-20 w-20 bg-[#171036] rounded-full border-[10px] border-[#171036]">
                            <HashLink to="/#inquiry" scroll={(element) => element.scrollIntoView({ behavior: 'smooth', block: 'start' })}>
                                <button className="flex flex-col items-center justify-center rounded-full w-full h-full bg-gradient-to-br from-red-500 to-yellow-500 hover:from-yellow-500 hover:to-red-500 hover:-translate-y-1 trasition duration-300 group">
                                    <LuMailPlus className="text-white w-[45%] h-[45%]" />
                                    <p className="text-[8px] text-white font-bold">{ t("bottomNavigationInquiry") }</p>
                                </button>
                            </HashLink>
                        </div>
                        <div className="absolute z-[-1] bottom-0 h-[80%] left-1/2 -translate-x-1/2 w-1/2 bg-[#171036]"></div>
                    </div>
                </div>
            </Mobile>
        </div>
    )
}

export default Navigation;