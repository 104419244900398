import React from "react";
import { useMediaQuery } from 'react-responsive';
import { BiArrowBack } from "react-icons/bi";
import { HashLink } from 'react-router-hash-link';

const Navigation = ({ onClickSettings }) => {
    const Desktop = ({ children }) => useMediaQuery({ minWidth: 768 }) ? children : null;
    const Mobile = ({ children }) => useMediaQuery({ maxWidth: 767 }) ? children : null;
    return (
        <div>
            <Desktop>
            </Desktop>
            <Mobile>
                <div className="fixed top-0 left-0 w-full z-50 h-14 bg-gray-50 shadow flex items-center text-3xl">
                    <HashLink className="ml-5" to="/#header" scroll={(element) => element.scrollIntoView({ behavior: 'smooth', block: 'start' })}>
                        <BiArrowBack />
                    </HashLink>
                </div>
            </Mobile>
        </div>
    )
}

export default Navigation;