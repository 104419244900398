import React from "react";
import { useMediaQuery } from "react-responsive";
import { useTranslation } from 'react-i18next';

const CompanyAppeal = () => {
    const Desktop = ({ children }) => useMediaQuery({ minWidth: 768 }) ? children : null;
    const Mobile = ({ children }) => useMediaQuery({ maxWidth: 767 }) ? children : null;
    const { t } = useTranslation();
    return (
        <div>
            <Desktop>
                <section id="company" className="flex flex-col justify-center items-center mt-[100px] mb-[50px] space-y-2">
                    {/* <div className="w-[70px] border-b border-4 border-[#171036]"></div> */}
                    <div className="text-2xl text-[#171036] font-bold">{ t("companyAppealSectionTitle") }</div>
                </section>
                <div className="flex flex-col items-center bg-gray-50 pt-20 pb-20 rounded-xl text-[#171036] space-y-7 ">
                    <h2 className="text-2xl font-extrabold tracking-wide">
                        Welcome to CLP Career !
                    </h2>
                    <p className="text-xl z-10 tracking-wide leading-[60px]">
                        {t("companyAppealMessage").split('\n').map((text, index) => (
                            <React.Fragment key={ index }>{ text }<br /></React.Fragment>
                        ))}
                    </p>
                </div>
            </Desktop>

            <Mobile>
                <section id="company" className="flex flex-col justify-center items-center pt-10">
                    {/* <div className="w-[40px] border-b border-2 border-[#171036]"></div> */}
                    <div className="text-lg text-[#171036] font-bold">{ t("companyAppealSectionTitle") }</div>
                </section>
                <div className="bg-gray-50 p-4 m-4 rounded-xl text-[#171036] space-y-3">
                    <h2 className="text-center text-xl font-extrabold tracking-wide">
                        Welcome to CLP Career !
                    </h2>
                    <p className="text-sm tracking-wide leading-7 break-words">
                        {t("companyAppealMessageMobile").split('\n').map((text, index) => (
                            <React.Fragment key={ index }>{ text }<br /></React.Fragment>
                        ))}
                    </p>
                </div>
            </Mobile>
        </div>
    )
}
export default CompanyAppeal;