import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import langKo from './ko.json';
import langJa from './ja.json';
import langEn from './en.json';

const resources = {
  ko: {
    translation: langKo,
  },
  ja: {
    translation: langJa,
  },
  en: {
    translation: langEn,
  },
};
i18n
.use(LanguageDetector)
.use(initReactI18next)
.init({
  resources,
  fallbackLng: 'ja',
  // lng: 'en',
});
export default i18n;