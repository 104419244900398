import React from 'react';
import { useMediaQuery } from "react-responsive";
import { useTranslation } from 'react-i18next';

function Terms() {
    const Desktop = ({ children }) => useMediaQuery({ minWidth: 768 }) ? children : null;
    const Mobile = ({ children }) => useMediaQuery({ maxWidth: 767 }) ? children : null;
    const { t } = useTranslation();
    const termsData = [
        { title: "termsTitle01", contents: "termsContents01"},
        { title: "termsTitle02", contents: "termsContents02"},
        { title: "termsTitle03", contents: "termsContents03"},
        { title: "termsTitle04", contents: "termsContents04"},
        { title: "termsTitle05", contents: "termsContents05"},
        { title: "termsTitle06", contents: "termsContents06"},
        { title: "termsTitle07", contents: "termsContents07"},
        { title: "termsTitle08", contents: "termsContents08"},
        { title: "termsTitle09", contents: "termsContents09"},
        { title: "termsTitle10", contents: "termsContents10"},
        { title: "termsTitle11", contents: "termsContents11"},
        { title: "termsTitle12", contents: "termsContents12"}
    ];
  return (
    <div>
        <Desktop>
            <div id="terms" className="m-auto mt-40 mb-20 w-[1080px] space-y-10 text-[#171036]">
                <div className="text-4xl font-bold text-center">{ t("termsSectionTitle") }</div>
                {termsData.map((term, index) => (
                    <React.Fragment key={ index }>
                        <div className="text-2xl font-bold">{ t(term.title) }</div>
                        <div>
                            {t(term.contents).split('\n').map((text, index) => (
                                <React.Fragment key={ index }>{ text }<br /><br /></React.Fragment>
                            ))}
                        </div>
                    </React.Fragment>
                ))}
            </div>
        </Desktop>

        <Mobile>
            <div id="terms" className="m-10 mt-20 text-xs space-y-5 text-[#171036]">
                <div className="text-xl font-bold text-center">{ t("termsSectionTitle") }</div>
                {termsData.map((term, index) => (
                    <React.Fragment key={ index }>
                        <div className="text-sm font-bold">{ t(term.title) }</div>
                        <div>
                            {t(term.contents).split('\n').map((text, index) => (
                                <React.Fragment key={ index }>{ text }<br /><br /></React.Fragment>
                            ))}
                        </div>
                    </React.Fragment>
                ))}
            </div>
        </Mobile>
    </div>
  );
}

export default Terms;