import React from "react";
import { useMediaQuery } from 'react-responsive';
import { useTranslation } from 'react-i18next';
import truck from "../assets/truck.png";
import profile from "../assets/profile.png";

const Banner = () => {
    const Desktop = ({ children }) => useMediaQuery({ minWidth: 768 }) ? children : null;
    const Mobile = ({ children }) => useMediaQuery({ maxWidth: 767 }) ? children : null;
    const { t } = useTranslation();
    return (
        <div>
        <Desktop>
            <div id="banner" className="m-auto pt-40 pl-20 pr-20 bg-[#171036]">
                <img className="rounded-t-[40px] m-auto w-[1080px]" src={ t("bannerImageUrl") } alt="banner" />
            </div>
            <div className="m-auto w-[900px]">
                <img src={ t("bannerAdditionImageUrl") } alt="banner addition" />
            </div>
        </Desktop>

        <Mobile>
            <div id="banner" className="flex justify-center items-center">
                <img src={ t("bannerMobileImageUrl") } alt="banner" />
            </div>
            <div className="flex flex-col">
                <div className="flex items-center mx-3 my-1 w-[80%] h-[90px] border-[2px] border-[#0A1587] rounded-[20px] shadow-md">
                    <div>
                        <div className="w-2 h-2 rounded-full border-[2.5px] border-[#0A1587] mx-2 my-9"></div>
                        <div className="w-2 h-2 rounded-full border-[2.5px] border-[#0A1587] mx-2 my-9"></div>
                    </div>
                    <img className="w-[65px]" src={ t("bannerMobileFlagImageUrl") } alt="flag" />
                    <div className="p-3 text-[#171036] text-xs">{ t("bannerDetail01") }</div>
                </div>
                <div className="flex items-center mx-3 my-1 ml-auto w-[80%] h-[90px] border-[2px] border-[#0A1587] rounded-[20px] shadow-md">
                    <div className="p-3 text-[#171036] text-xs">{ t("bannerDetail02") }</div>
                    <img className="ml-auto w-[50px]" src={ truck } alt="truck" />
                    <div className="ml-auto">
                        <div className="w-2 h-2 rounded-full border-[2.5px] border-[#0A1587] mx-2 my-9"></div>
                        <div className="w-2 h-2 rounded-full border-[2.5px] border-[#0A1587] mx-2 my-9"></div>
                    </div>
                </div>
                <div className="flex items-center mx-3 my-1 w-[80%] h-[90px] border-[2px] border-[#0A1587] rounded-[20px] shadow-md">
                    <div>
                        <div className="w-2 h-2 rounded-full border-[2.5px] border-[#0A1587] mx-2 my-9"></div>
                        <div className="w-2 h-2 rounded-full border-[2.5px] border-[#0A1587] mx-2 my-9"></div>
                    </div>
                    <img className="w-[50px]" src={ profile } alt="profile" />
                    <div className="p-3 text-[#171036] text-xs">{ t("bannerDetail03") }</div>
                </div>
            </div>
        </Mobile>
    </div>
    )
}
export default Banner;